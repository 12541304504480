import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const ProfileSkeleton = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ mb: 2, p: 1, boxShadow: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="rectangular" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="80px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="100px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="120px" height="40px" animation="wave" />
          <Skeleton variant="rectangular" width="160px" height="40px" animation="wave" />
        </Stack>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, boxShadow: 3, height: 440 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Skeleton variant="rectangular" width="100%" height={150} animation="wave" sx={{ borderRadius: 2 }} />
                <Skeleton variant="text" width="80%" height={30} animation="wave" sx={{ mt: 2 }} />
                <Skeleton variant="text" width="60%" height={20} animation="wave" />
                <Skeleton variant="text" width="90%" height={20} animation="wave" sx={{ mt: 1 }} />
                <Skeleton variant="text" width="40%" height={40} animation="wave" sx={{ mt: 2 }} />
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={2}>
                  {[...Array(6)].map((_, index) => (
                    <Skeleton key={index} variant="text" width="100%" height={20} animation="wave" />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, boxShadow: 3, height: 440 }}>
            <Stack spacing={2}>
              <Skeleton variant="text" width="50%" height={30} animation="wave" />
              {[...Array(10)].map((_, index) => (
                <Skeleton key={index} variant="text" width="100%" height={20} animation="wave" />
              ))}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileSkeleton;
