import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddNewStudentStep from './AddStudentsSteps/AddNewStudentStep';
import AddFeePlanStep from './AddStudentsSteps/AddFeePlanStep';
import { styled } from '@mui/material/styles';

const steps = ['Basic Information', 'Save Fee Plan'/* , 'Print Voucher' */];

const DiamondStepIcon = styled('div')(({ theme, active, completed }) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  transform: 'rotate(45deg)',
  backgroundColor: completed ? theme.palette.primary.main : active ? theme.palette.primary.main : theme.palette.grey[300],
  color: theme.palette.common.white,
  fontWeight: 'bold',
  marginTop:"-6px",
  zIndex:1
}));

const DiamondStepIconContent = styled('div')({
  transform: 'rotate(-45deg)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  fontSize: '16px',
});

const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
  '& .MuiStepLabel-label': {
    color: active ? theme.palette.primary.main : theme.palette.grey[500],
    marginTop: '8px',
    fontWeight: active ? 'bold' : 'normal',
    textAlign: 'center',
    fontSize: '12px',
  },
}));

export default function AddSingleStudentStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [student,setstudent]=React.useState()

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  let stepComps = [
    <AddNewStudentStep handleNext={handleNext} setstudent={setstudent}/>,
    <AddFeePlanStep handleNext={handleNext} student={student}/>,
  ];

  useEffect(()=>{
    return()=>{
      setstudent({})
    }
  },[])

  return (
    <Box sx={{ width: '100%', padding: '0px 0' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <CustomStepLabel
              active={index === activeStep}
              StepIconComponent={(props) => (
                <DiamondStepIcon
                  {...props}
                  active={index === activeStep}
                  completed={index < activeStep}
                >
                 <DiamondStepIconContent>
                    {index + 1}
                  </DiamondStepIconContent>
                </DiamondStepIcon>
              )}
            >
              {label}
            </CustomStepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 5, mb: 1 }}>{stepComps[activeStep]} </Box>
          {/* {
            activeStep !== 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
            )

            
          } */}
       
        </React.Fragment>
      )}
    </Box>
  );
}
