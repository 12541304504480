import { Fragment, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// @mui
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Heading from 'src/components/heading';
import StyledDataGrid from 'src/components/styleDataGrid/styledDataGrid';
import TableSkeleton from 'src/components/tableSkeleton';

import ExpandableSearchbar from 'src/components/expandableSearchbar';
import { Grid } from '@mui/material';
import ClassSelector from 'src/components/classSelector';
import SectionSelector from 'src/components/sectionSelector';
import { VIEW_STUDENTS_API_HANDLER } from 'src/redux/actions/studentActions';
import urls from 'src/constants/configs/routApis';
import { fetchWrapper } from 'src/Utils/fetchWrapper';

const StruckOffStudentsList = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [students, setStudents] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [recall, setRecall] = useState(false);

  const [selectedSection, setSelectedSection] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSession, setSelectedSession] = useState('')
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      minWidth: 120,
      field: 'candidateRollNo',
      headerName: 'Roll No / Adm No',
      renderCell: ({ row }) => {
        const { candidateRollNo } = row;
        return candidateRollNo;
      }
    },
    {
      minWidth: 180,
      flex: 1,
      headerName: 'Name',
      field: 'firstName',
      renderCell: ({ row }) => {
        const { firstName,lastName } = row;
        return `${firstName} ${lastName}`;
      }
    },
    {
      minWidth: 160,
      flex: 1,
      headerName: 'Father Name',
      field: 'lastName',
      renderCell: ({ row }) => {
        const { fatherName } = row;
        return fatherName;
      }
    },
    {
      minWidth: 60,
      flex: 1,
      headerName: 'Section',
      field: 'sectionName',
      renderCell: ({ row }) => {
        if (row.sectioninfo) {
          const { sectionName } = row.sectioninfo;
          return sectionName;
        }
        return 'All';
      }
    },
    {
      minWidth: 120,
      flex: 0.7,
      sortable: false,
      field: 'cellNo',
      headerName: 'Cell No',
      renderCell: ({ row }) => {
        const { cell_no } = row;
        return cell_no;
      }
    }
  ];

  const getStudents = async () => {
    const data = await dispatch(VIEW_STUDENTS_API_HANDLER({ params: { status: 0, classId: selectedClass, sectionId: selectedSection, sessionId: selectedSession } }));
    if (data?.length === 0) {
      toast.error('No students found');
    }

    setStudents({ rows: data });
    setFirstLoading(false);
    setIsLoading(false);
    setRecall(false)

  }

  useEffect(() => {
    setIsLoading(true);
    getStudents()
  }, [dispatch,recall]);

  const filteredStudents = useMemo(() => {
    if (searchInput === '' && students) return students.rows;
    if (students === null) return [];

    const searchValue = searchInput.toLowerCase();
    return students.rows.filter((student) => {
      return student.queriableString.includes(searchValue);
    });
  }, [searchInput, students]);

  const struckOff = async () => {
    const response = await fetchWrapper('POST', urls.STUCK_OFF, { students: selectedRows });
    if (response?.data?.data) {
      getStudents()
      toast.success(response?.data?.message);
    }

  }

  function clearAll() {
    setSelectedClass('');
    setSelectedSection('');
    setRecall(true)
}


  return (
    <Fragment>
      <Helmet>
        <title> Students | EduPortal.pk </title>
      </Helmet>

      <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }}>
        <Stack alignSelf={{ xs: 'flex-start' }} sx={{ gap: 1, width: { xs: '100%', md: 'auto' } }}>
          <Stack direction={{ sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" width="100%">
            <Heading title={students ? `View Students (${students.rows.length} records)` : 'View Students'} />
          </Stack>
        </Stack>
      </Stack>

      <Box mt={2}>
        <Paper sx={{ p: 3, boxShadow: 2 }}>

          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={6} md={4}>
              <SessionSelector value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)} />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <ClassSelector
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SectionSelector value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)} selectedClass={selectedClass} />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ gap: 1, width: '100%', height: '100%' }}>
                <Button variant="outlined" onClick={clearAll}>
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={getStudents}
                >
                  Filter
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Stack direction={{ md: 'row' }} alignItems={{ md: 'center' }} gap={{ xs: 2, md: 0 }} justifyContent="center" mt={2}>
        <ExpandableSearchbar value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={struckOff}
              disabled={(selectedRows.length == 0)}
              
            >
              Struck Off
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Paper elevation={4} sx={{ overflow: 'hidden', mt: 2 }}>
        {firstLoading ? (
          <TableSkeleton />
        ) : (
          <StyledDataGrid
            autoHeight
            disableSelectionOnClick
            checkboxSelection={true}
            hideFooterSelectedRowCount
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            loading={isLoading}
            rows={filteredStudents}
            getRowId={(row) => row.studentId}
            onRowSelectionModelChange={(selectedModel) => {
              setSelectedRows(selectedModel.map((el) => ({ studentId: el })));
            }}
          />
        )}
      </Paper>
    </Fragment>
  );
};

export default StruckOffStudentsList;
