import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import SettingsIcon from '@mui/icons-material/Settings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InventoryIcon from '@mui/icons-material/Inventory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ComputerIcon from '@mui/icons-material/Computer';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import {
  attendanceIndex,
  blankAttendanceSheet,
  editAttendance,
  feeReportsAndSettings,
  feesIndex,
  generatChallan,
  index,
  markAttendance,
  smsAttendance,
  viewAttendance,
  viewChallan,
  classwiseFeeUpdate,
  feeSettigs,
  feeRecievableReport,
  headwiseReport,
  userProfitLostReport,
  installmentStudents,
  multiMonthsFeeReport,
  studentSearchWithFee,
  totalDatewiseFeeReport,
  datewiseFeeCollectionReport,
  arrearsReport,
  userwiseFeeReport,
  attendanceReports,
  dayAttendanceSummary,
  datewiseAttendanceReport,
  instituteAttendanceSummary,
  familyFeeAndReporting,
  familiesFeeReport,
  filledAttendanceSheet,
  mostAbsentees,
  dailyAttendanceReport,
  studentsIndex,
  addStudents,
  viewStudents
} from 'src/constants/configs/pagesRoutesText';

// colors
const activeColor = 'grey.50';
const nonActiveColor = 'grey.500';
export const icons = {
  Dashboard: (isActive) => <DashboardOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Students: (isActive) => <PeopleOutlineIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Attendance: (isActive) => <CheckCircleOutlineIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'SMS/Emails': (isActive) => <MailOutlineIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Fee Collection': (isActive) => <AttachMoneyIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Examination: (isActive) => <NoteAltOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Employees/HRM': (isActive) => <GroupsOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Reports: (isActive) => <AssessmentOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  PayRoll: (isActive) => <AccountBalanceIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Expenses: (isActive) => <AttachMoneyIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Front Office': (isActive) => <ComputerIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Class & Sections': (isActive) => <SchoolOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'System Settings': (isActive) => <SettingsIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Staff Portal': (isActive) => <AccountCircleOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Payment Invoices': (isActive) => <ReceiptOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Parents Portal': (isActive) => <SupervisorAccountIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Customer Support': (isActive) => <ContactSupportOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'LMS (Online Test)': (isActive) => <AutoStoriesOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Timetable: (isActive) => <ScheduleIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Cards Generator': (isActive) => <CreditCardOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Certificates: (isActive) => <WorkspacePremiumOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Inventory/ Assets': (isActive) => <InventoryIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Accounts: (isActive) => <AccountBalanceIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Datesheet: (isActive) => <CalendarMonthOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Transport: (isActive) => <DirectionsBusIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  Library: (isActive) => <MenuBookIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Download Center': (isActive) => <CloudDownloadIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Point of Sale': (isActive) => <LoyaltyOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Hostel System': (isActive) => <HotelIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />,
  'Multi Campuses': (isActive) => <BusinessOutlinedIcon fontSize="small" sx={{ color: isActive ? activeColor : nonActiveColor }} />
};

export const internalRoutesList = [
  index,
  `${attendanceIndex}/${markAttendance}`,
  `${attendanceIndex}/${viewAttendance}`,
  `${attendanceIndex}/${smsAttendance}`,
  blankAttendanceSheet,

  // fees
  `${feesIndex}/${viewChallan}`,
  `${feesIndex}/${generatChallan}`,
  `${feesIndex}/${feeReportsAndSettings}`,
  `${feesIndex}/${familyFeeAndReporting}`,

  
  `${studentsIndex}/${addStudents}`,
  // `${studentsIndex}/${viewStudents}`,

];

export const sidebarCollapse = {
  [index]: 'Dashboard',

  // attendance
  [`${attendanceIndex}/${markAttendance}`]: 'Attendance',
  [`${attendanceIndex}/${viewAttendance}`]: 'Attendance',
  [`${blankAttendanceSheet}`]: 'Attendance',
  [`${attendanceIndex}/${editAttendance}`]: 'Attendance',
  [`${attendanceIndex}/${smsAttendance}`]: 'Attendance',
  [`${attendanceIndex}/${attendanceReports}`]: 'Attendance',
  [`${attendanceIndex}/${dayAttendanceSummary}`]: 'Attendance',
  [`${attendanceIndex}/${datewiseAttendanceReport}`]: 'Attendance',
  [`${attendanceIndex}/${instituteAttendanceSummary}`]: 'Attendance',
  [`${attendanceIndex}/${filledAttendanceSheet}`]: 'Attendance',
  [`${attendanceIndex}/${mostAbsentees}`]: 'Attendance',
  [`${attendanceIndex}/${dailyAttendanceReport}`]: 'Attendance',

  // fees
  [`${feesIndex}/${viewChallan}`]: 'Fee Collection',
  [`${feesIndex}/${generatChallan}`]: 'Fee Collection',
  [`${feesIndex}/fee-reports%20&%20settings`]: 'Fee Collection',
  [`${feesIndex}/${classwiseFeeUpdate}`]: 'Fee Collection',
  [`${feesIndex}/${feeSettigs}`]: 'Fee Collection',
  [`${feesIndex}/${feeRecievableReport}`]: 'Fee Collection',
  [`${feesIndex}/${headwiseReport}`]: 'Fee Collection',
  [`${feesIndex}/${userProfitLostReport}`]: 'Fee Collection',
  [`${feesIndex}/${installmentStudents}`]: 'Fee Collection',
  [`${feesIndex}/${multiMonthsFeeReport}`]: 'Fee Collection',
  [`${feesIndex}/${studentSearchWithFee}`]: 'Fee Collection',
  [`${feesIndex}/${totalDatewiseFeeReport}`]: 'Fee Collection',
  [`${feesIndex}/${datewiseFeeCollectionReport}`]: 'Fee Collection',
  [`${feesIndex}/${arrearsReport}`]: 'Fee Collection',
  [`${feesIndex}/${userwiseFeeReport}`]: 'Fee Collection',
  [`${feesIndex}/${familyFeeAndReporting}`]: 'Fee Collection',
  [`${feesIndex}/${familiesFeeReport}`]: 'Fee Collection',

  [`${studentsIndex}/${addStudents}`]: 'Students',
  // [`${studentsIndex}/${viewStudents}`]: 'Students',


  
};
