import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useClasses } from 'src/hooks/useClasses';

const ClassSelector = ({ togglealloption = false, value, onChange, label = 'Class',required = false, shrinkLabel = false, sx, ...props }) => {
  const { classes, isLoading } = useClasses();

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height="20px" animation="wave" />;
  }

  return (
    <FormControl fullWidth sx={sx} required={required}>{
      shrinkLabel ?
        <InputLabel id="demo-simple-select-label" shrink={shrinkLabel}>{label}</InputLabel>
        :
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
    }
      <Select
        label={label}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 250,
            }
          }
        }}
        value={value}
        onChange={onChange}
        {...props}
      >
        {togglealloption && <MenuItem value="all">All</MenuItem>}
        {classes &&
          Object.entries(classes).map(([key, value]) => {
            return (
              <MenuItem key={key} value={key}>
                {value.ClassName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default ClassSelector;
