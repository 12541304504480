import { handleError } from 'src/Utils/errorHandling';
import { types } from 'src/redux/types';
import * as Services from 'src/services';

export const GET_CLASSWISE_CHALLAN = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.CLASSWISE_CHALLAN(additionalOptions);
    const challans = data.result.map((challan, index) => ({ ...challan, index }));
    data.result = challans;
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const DELETE_CHALLAN_HANDLER = (body) => async (dispatch) => {
  try {
    const { data } = await Services.DELETE_CHALLAN(body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const SEND_CHALLAN_SMS = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.CHALLAN_SMS(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GET_FEE_HEADS = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.FEE_HEADS(additionalOptions);
    const heads = data.result.map((head) => {
      const originalHead = data.originalFeeHeads.find((OH) => OH.feetype_id === head.feetype_id);
      return { ...head, originalName: originalHead.feetype_name };
    });
    dispatch({
      type: types.FEE_HEADS.FETCH_SUCCESS,
      payload: heads
    });
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const POST_FEE_HEADS = (additionalOptions, body) => async (dispatch) => {
  try {
    const { data } = await Services.POST_FEE_HEADS(additionalOptions, body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GET_STUDENTS_CHALLAN = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.STUDENTS_CHALLAN(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GENERATE_CHALLAN_HANDLER = (body) => async (dispatch) => {
  try {
    const { data } = await Services.GENERATE_CHALLAN(body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GET_SINGLE_CHALLAN = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.GET_SINGLE_CHALLAN(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const EDIT_CHALLAN_HANDLER = (body) => async (dispatch) => {
  try {
    const { data } = await Services.EDIT_SINGLE_CHALLAN(body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GET_PAYMENT_DETAILS_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.GET_PAYMENT_DETAILS(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const SAVE_PAYMENTS_HANDLER = (body) => async (dispatch) => {
  try {
    const { data } = await Services.SAVE_PAYMENTS(body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const UNPAY_PAYMENT_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.UNPAY_PAYMENT(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const Get_FAMILY_VOUCHER_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.Get_FAMILY_VOUCHER(additionalOptions);
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const GET_FEE_SETTINGS_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    const { data } = await Services.GET_FEE_SETTINGS(additionalOptions);
    dispatch({
      type: types.FEE_SETTINGS.FETCH_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const POST_FEE_SETTINGS_HANDLER = (body) => async (dispatch) => {
  try {
    const { data } = await Services.POST_FEE_SETTINGS(body);
    return data;
  } catch (error) {
    return handleError(error);
  }
};
