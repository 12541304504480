import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
// @mui
// import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

// icons
// import SearchIcon from '@mui/icons-material/Search';

// custom components
import { useStudents } from 'src/hooks/useStudents';
// import { useSessionKey } from 'src/hooks/useSessionKey';
// import { externalLinkGenerator } from 'src/Utils/externalLink';
import { studentsIndex, studentProfile } from 'src/constants/configs/pagesRoutesText';

export default function Searchbar() {
    const [open, setOpen] = useState(false);
    // const sessionKey = useSessionKey();
    const navigate = useNavigate();

    const showOptions = () => {
        setOpen(true);
    };
    const hideOptions = () => {
        setOpen(false);
    };
    // ** hooks
    const { studentsData, isLoading, error } = useStudents();

    // functionality
    const filterOptions = (event, inputValue) => {
        if (inputValue !== '') {
            showOptions();
        }
        const query = inputValue.toLowerCase().trim();
        const filteredStudents = studentsData.filter((student) => {
            return student.queriableString.includes(query);
        });
        return filteredStudents;
    };

    function getLabel(option) {
        const candidateRollNo = option?.candidateRollNo ?? '';
        const firstName = option?.firstName ?? '';
        const cellNo = option?.cellNo ?? '';
        const familyCode = option?.familyCode ?? '';
        const lastName = option?.lastName ?? '';
        const className = option?.classinfo?.ClassName ?? '';
        const sectionName = option?.sectioninfo?.sectionName ?? '';
        const refNo = option?.refNo ?? '';

        return `${candidateRollNo} | ${firstName} | ${lastName} | ${className} | ${sectionName} | ${refNo} | ${cellNo} | ${familyCode}`;
    }

    function onChangeHandler(event, newValue) {
        if (newValue) {
            navigate(`${studentsIndex}/${studentProfile}`, { state: { id: newValue?.encStudentId } });
            // const url = externalLinkGenerator('/student_profile.php', {
            //     student: studentId,
            //     key: sessionKey
            // });
            // window.open(url.toString(), '_blank');
        }
    }
    if (error) {
        return (
            <Box
                sx={{
                    width: '100%',
                    padding: 1,
                    pl: 4,
                    backgroundColor: 'error.main',
                    borderRadius: 999999
                }}
            >
                Contact admin for more info.
            </Box>
        );
    }
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={studentsData}
            getOptionLabel={(option) => getLabel(option)}
            onInputChange={filterOptions}
            loading={isLoading}
            onChange={onChangeHandler}
            open={open}
            onClose={hideOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    // InputProps={{
                    // 	startAdornment: (
                    // 		<InputAdornment position='start'>
                    // 			<SearchIcon sx={{ color: 'grey.500' }} />
                    // 		</InputAdornment>
                    // 	),
                    // }}
                    placeholder="Search Student with | Name | Cell No | Roll No | Adm No "
                    size="small"
                    sx={{
                        border: '0',
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'grey.200',
                            borderRadius: 999999
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 0
                        }
                    }}
                />
            )}
        />
    );
}
