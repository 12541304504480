import { useQuery } from '@tanstack/react-query';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';

export const useStates = () => {

  const { data: statesList, isPending: isLoading, error } = useQuery({

    queryKey: ['localities-states'],
    queryFn: async () => {
      const response = await fetchWrapper('GET', urls.CREATE_LOCALITY_STATES);
      return response.data?.data;
    }
  });

  return {
    isLoading,
    statesList,
    error
  };
};
