

import { Fragment, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// @mui
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import Heading from 'src/components/heading';
import StyledDataGrid from 'src/components/styleDataGrid/styledDataGrid';
import TableSkeleton from 'src/components/tableSkeleton';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import MoreVertIcon from '@mui/icons-material/MoreVert';

// custom
import ExpandableSearchbar from 'src/components/expandableSearchbar';
import { Grid } from '@mui/material';
import ClassSelector from 'src/components/classSelector';
import SectionSelector from 'src/components/sectionSelector';
import { VIEW_STUDENTS_API_HANDLER } from 'src/redux/actions/studentActions';
import SessionSelector from 'src/components/sessionSelector';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { addStudents, studentsIndex } from 'src/constants/configs/pagesRoutesText';
import useUser from 'src/hooks/useUser';
import { useSessions } from 'src/hooks/useSessions';

// import SessionSelector from 'src/components/sessionSelector';

const ViewStudents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { USERDATA: user } = useUser();
  const { sessions: yearSessions } = useSessions();


  const [isLoading, setIsLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [students, setStudents] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [actionRow, setActionRow] = useState(null);
  const [showPopover, setShowPopover] = useState(null);
  const [recall, setRecall] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSession, setSelectedSession] = useState('')

  const columns = [
        {
      field: 'index',
      headerName: 'Sr. No',
      width: 30,
      renderCell: ({ row }) => row.index + 1
    },
    {
      minWidth: 120,
      field: 'rollNumber',
      headerName: 'G.R No',
      renderCell: ({ row }) => {
        const { rollNumber } = row;
        return rollNumber;
      }
    },
    {
      minWidth: 180,
      flex: 1,
      headerName: 'Name',
      field: 'firstName',
      renderCell: ({ row }) => (
        <Link
          color="text.primary"
          underline="hover"
          onClick={() => openStdProfile(row.studentId)}
          component="button"
        >{`${row.firstName}`}</Link>
      )
    },
    {
      minWidth: 40,
      flex: 1,
      headerName: 'Father Name',
      field: 'lastName',
      renderCell: ({ row }) => {
        const { fatherName } = row;
        return fatherName;
      }
    },
    {
      minWidth: 60,
      flex: 1,
      headerName: 'Class',
      field: 'className',
      renderCell: ({ row }) => {
        return row['classinfo.ClassName'] ?? 'All';
      }
    },

    {
      minWidth: 30,
      flex: 0.8,
      headerName: 'Section',
      field: 'sectionName',
      renderCell: ({ row }) => {
        return row['sectioninfo.sectionName'] ?? 'All';
      }
    },
    {
      minWidth: 120,
      flex: 0.6,
      sortable: false,
      field: 'cellNo',
      headerName: 'Cell No',
      renderCell: ({ row }) => {
        const { cell_no } = row;
        return cell_no;
      }
    },
    {
      minWidth: 30,
      flex: 0.4,
      sortable: false,
      field: 'menu',
      headerName: 'Options',
      renderCell: ({ row }) => {

        return (
          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
            <MoreVertIcon />
          </IconButton>
        );
      }
    }
  ];

  const getStudents = async ({ defaultSession }) => {
    const data = await dispatch(VIEW_STUDENTS_API_HANDLER({ params: { status: 0, classId: selectedClass == 'all' ? "" : selectedClass, sectionId: selectedSection, sessionId: defaultSession ? defaultSession : selectedSession } }));


    if (data?.length === 0) {
      toast.error('No students found');
    }

    setStudents({ rows: data });
    setFirstLoading(false);
    setIsLoading(false);
    setRecall(false)

  }

  useEffect(() => {
    setIsLoading(true);
    const branchSession = yearSessions.find((sess) => sess.sessionYear === user.sessionYear);
    if (branchSession) {
      setSelectedSession(branchSession.sessionId)
      students == null && branchSession?.sessionId && isLoading == true && getStudents({ defaultSession: branchSession.sessionId })

    }
  }, [dispatch, recall, yearSessions, user]);

  const filteredStudents = useMemo(() => {
    if (searchInput === '' && students) return students.rows;
    if (students === null) return [];

    const searchValue = searchInput.toLowerCase();
    
    return students.rows.filter((student) => {
        return Object.values(student).some(value => 
            value?.toString()?.toLowerCase()?.includes(searchValue)
        );
    });
}, [searchInput, students]);



  function clearAll() {
    setSelectedClass('');
    setSelectedSection('');
    setRecall(true)
  }

  const handleOpenMenu = (event, row) => {
    setActionRow(row);
    setShowPopover(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowPopover(null);
    setActionRow(null);
  };

  const openStdProfile = (id) => {
    navigate(`/students/student-profile`, {
      state: {
        id: id ? id : actionRow?.studentId
      }
    })
  }

  const editStdProfile = (id) => {
    navigate(`${studentsIndex}/${addStudents}`, {
      state: {
        id: actionRow?.studentId
      }
    })
  }
  return (
    <Fragment>
      <Helmet>
        <title> Students | EduPortal.pk </title>
      </Helmet>

      <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }}>
        <Stack alignSelf={{ xs: 'flex-start' }} sx={{ gap: 1, width: { xs: '100%', md: 'auto' } }}>
          <Stack direction={{ sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" width="100%">
            <Heading title={students ? `View Students (${students.rows.length} records)` : 'View Students'} />
          </Stack>
        </Stack>
      </Stack>

      <Box mt={2}>
        <Paper sx={{ p: 3, boxShadow: 2 }}>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <SessionSelector value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ClassSelector
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
                togglealloption
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SectionSelector value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)} selectedClass={selectedClass} />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ gap: 1, width: '100%', height: '100%' }}>
                <Button variant="outlined" onClick={clearAll} y>
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={getStudents}
                >
                  Filter
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Stack direction={{ md: 'row' }} alignItems={{ md: 'center' }} gap={{ xs: 2, md: 0 }} justifyContent="center" mt={2}>
        <ExpandableSearchbar value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
        </Stack>
      </Stack>
      <Paper elevation={4} sx={{ overflow: 'hidden', mt: 2 }}>
        {firstLoading ? (
          <TableSkeleton />
        ) : (
          <StyledDataGrid
            autoHeight
            disableSelectionOnClick
            checkboxSelection={false}
            hideFooterSelectedRowCount
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            loading={isLoading}
            rows={filteredStudents}
            getRowId={(row) => row?.studentId}
          />
        )}
      </Paper>
      <Popover
        open={Boolean(showPopover)}
        anchorEl={showPopover}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <MenuItem onClick={() => openStdProfile()}>
          <PersonOutlineIcon sx={{ mr: 2 }} />
          Profile
        </MenuItem>

        <MenuItem onClick={() => editStdProfile()}>
          <ModeEditOutlineOutlinedIcon sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={OpenIndividualDelete} disabled={!studentsPrivileges?.deleteAccess}>
          <DeleteOutlinedIcon sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </Fragment>
  );
};

export default ViewStudents;
