import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useSessions } from 'src/hooks/useSessions';
import useUser from 'src/hooks/useUser';

const getCurrentAndNextSessions = (sessions,current) => {

  const sortedSessions = sessions.sort((a, b) =>
    a.sessionYear.localeCompare(b.sessionYear)
  );

  const currentIndex = sortedSessions.findIndex(
    (session) => session.sessionYear === current
  );

  const currentAndNextSessions = sortedSessions.slice(
    currentIndex,
    currentIndex + 2
  );

  return currentAndNextSessions;
};

const SessionSelector = ({
  value,
  label = 'Session',
  required=false,
  onChange,
  upcomingSessions,
  ...props
}) => {
  const { sessions, isLoading } = useSessions();
  const { USERDATA: user } = useUser();
    
  const branchSession = sessions?.find((sess) => sess?.sessionYear === user?.sessionYear);
  const filteredSessions =upcomingSessions? getCurrentAndNextSessions(sessions,branchSession?.sessionYear):sessions

  if (isLoading) {
    return (
      <FormControl fullWidth required={required}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          label={label}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 250,
              },
            },
          }}
          value={value}
          onChange={onChange}
          {...props}
        >
          {[1, 2, 3, 4, 5].map((session) => (
            <MenuItem key={session}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="20px"
                animation="wave"
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth required={required}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        label={label}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 250,
            },
          },
        }}
        value={value}
        onChange={onChange}
        {...props}
      >
        <MenuItem value="">Select Session</MenuItem>
        {filteredSessions.map((session) => (
          <MenuItem key={session.sessionId} value={session.sessionId}>
            {session.sessionYear}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SessionSelector;
