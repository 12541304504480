export const index = '/';
export const login = '/login';
export const error = '/error';
export const accessDenied = '/access-denied';

// Attendance
export const attendanceIndex = '/attendance';
export const blankAttendanceSheet = '/attendance/blank-attendance-sheet';
export const viewAttendance = 'attendance-reports';
export const markAttendance = 'mark-attendance';
export const editAttendance = 'edit-attendance';
export const smsAttendance = 'send-sms-report';
export const attendanceReports = 'advance-reports';
export const dayAttendanceSummary = 'day-attendance-summary';
export const datewiseAttendanceReport = 'datewise-attendance-report';
export const instituteAttendanceSummary = 'institute-attendance-summary';
export const filledAttendanceSheet = 'filled-attendance-sheet';
export const mostAbsentees = 'most-absentees';
export const dailyAttendanceReport = 'daily-attendance-report';

// Fee
export const feesIndex = '/fee-collection';
export const viewChallan = 'view-challan';
export const generatChallan = 'create-challan';
export const voucher = 'voucher';
export const headsSettings = 'heads-settings';
export const feeReportsAndSettings = 'fee-reports & settings';
export const feeSettigs = 'fee-settings';
export const classwiseFeeUpdate = 'classwise-fee-Update';
export const feeRecievableReport = 'fee-recievable-report';
export const headwiseReport = 'headwise-report';
export const userProfitLostReport = 'user-profit-lost-report';
export const installmentStudents = 'installments-students';
export const multiMonthsFeeReport = 'multi-months-fee-report';
export const studentSearchWithFee = 'student-search-with-fee';
export const totalDatewiseFeeReport = 'total-datewise-fee-report';
export const datewiseFeeCollectionReport = 'datewise-fee-collection-report';
export const arrearsReport = 'arrears-report';
export const userwiseFeeReport = 'userwise-fee-report';
export const familyFeeAndReporting = 'families-fee';
export const familiesFeeReport = 'families-fee-report';

// students
export const studentsIndex = '/students';
export const addStudents = 'add-students';
export const viewStudents='students-view';
// export const viewStudents='students-reports';
export const studentProfile='student-profile';
export const struckOffStudentsList='struckoff-students-list';


