import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_SESSIONS_API_HANDLER } from 'src/redux/actions/sessionsAction/index';
import useUser from './useUser';

export const useSessions = () => {
  // hook
  const dispatch = useDispatch();
  const { USERDATA: user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const sessions = useSelector((state) => state.SESSIONS);

  const currentSession = (sessions?.length > 0 && user) && sessions.find((sess) => sess?.sessionYear === user?.sessionYear)?.sessionId;


  useEffect(() => {
    if (sessions.length === 0) {
      setIsLoading(true);
      setIsLoading(true);
      dispatch(GET_SESSIONS_API_HANDLER())
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
          setError(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, sessions, currentSession, error };
};
