export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const daysInCurrentMonth = (year, month) => {
    const date = new Date();
    let defaultmonth = date.getMonth() + 1;
    let defaultyear = date.getFullYear();

    if (month) {
        defaultmonth = month;
    }
    if (year) {
        defaultyear = year;
    }

    // Get the number of days in the current month
    const daysInMonth = new Date(defaultyear, defaultmonth, 0).getDate();

    const currentMonthDays = [];

    for (let day = 1; day <= daysInMonth; day++) {
        currentMonthDays.push(day);
    }
    return currentMonthDays;
};


const isEqualObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
  };


  export const getDifferencesArray = (array1, array2) => {
    return array2.filter(obj2 => {
        return !array1.some(obj1 => isEqualObjects(obj1, obj2));
      });
    
  };
  
  export const encodeQueryParams = (params) => {
    return Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&');
  };

 export const formatDate=(dateString)=> {
    if(!dateString) return '--'
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  export const dummyProfileURL = "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"