import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// custom
import Modal from 'src/components/modal';
import { Grid, Typography, Box } from '@mui/material';
import toast from 'react-hot-toast';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';
import { useLocation } from 'react-router-dom';


const FamilyCreateModal = ({ open, onClose, studentData }) => {
    const cellNo  = studentData?.cellNo
    const location = useLocation();
    const studentId = location?.state?.id

    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        message: '',
        parentPassword: ''
    });

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit =async (e) => {
       try {
        e.preventDefault();
        if (formValues?.parentPassword?.length < 8) return toast.error('Password must be 8 digits')
        let data = {
            SID: studentId,
            parentPassword: formValues.parentPassword,
            parentName: studentData?.fatherName,
            sendSMS: true,
            message: formValues.message
        }
        setLoading(true)
        const response = await fetchWrapper('POST', urls.CREATE_FAMILY, data);
        if (response?.data?.status == 200) {
            onClose()
            toast.success(response?.data?.message);
          }else{
            toast.error(response?.data?.message)
          }
        setLoading(false)

       } catch (error) {
        setLoading(false)

       }
    };

    const generatePassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            password += chars[randomIndex];
        }
        setFormValues({
            ...formValues,
            parentPassword: password,
        });
    };
    return (
        <Modal heading="Create Parents IDs" open={open} onClose={onClose} customStyle={{ maxWidth: 1280 }}>
            <Box component="form" onSubmit={handleSubmit} p={4}>
                <Typography variant="h6" gutterBottom>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Mobile Number"
                            name="mobileNumber"
                            value={cellNo}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Message"
                            name="message"
                            value={formValues.message}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Parent Password"
                            name="parentPassword"
                            value={formValues.parentPassword}
                            onChange={handleChange}

                        />
                    </Grid>
                    {/* Button to generate password */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            onClick={generatePassword}
                        >
                            Generate Password
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default FamilyCreateModal;
