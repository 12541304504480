import React, { Fragment, useState } from 'react'
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddSingleStudentStepper from './AddSingleStudentStepper';
import AddMultiStudents from './AddMultiStudents';
import palette from 'src/@core/theme/palette';
import { styled } from '@mui/material/styles';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useSessionKey } from 'src/hooks/useSessionKey';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { Helmet } from 'react-helmet-async';
import { studentsIndex, viewStudents } from 'src/constants/configs/pagesRoutesText';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minHeight: "48px",
        borderRight: `1px solid silver`,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(12),
        color: 'black',
        '& .MuiTab-iconWrapper': {
            marginBottom: 0,
            marginRight: theme.spacing(1),
        },
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: `${palette.primary.main}`,
            border: "none"
        },
        '&.Mui-focusVisible': {
            backgroundColor: `${palette.primary.main}`,
        },
        '.MuiSvgIcon-root': {
            fontSize: '1.2rem',
        },
    }),
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}



function AddStudents() {
    const key = useSessionKey();

    const [tabs, setTabs] = useState(0);
    const handleTabsChange = (event, newValue) => {
        setTabs(newValue);
    };

    const classInstallmentTabUrl = externalLinkGenerator('/bulk_installment_stdns.php', { key });
    const importStudentsTabUrl = externalLinkGenerator('/import_data.php', { key });

    return (
        <Fragment>
             <Helmet>
        <title> Add Student | EduPortal.pk </title>
      </Helmet>
            <Box sx={{ p: 3 }}>
                <Paper elevation={2} style={{
                    overflow: "hidden"
                }}>
                    <StyledTabs value={tabs} onChange={handleTabsChange} aria-label="basic tabs example">
                        <StyledTab iconPosition="start"
                            icon={<PersonAddIcon />}
                            label={"Add Single Students"} {...a11yProps(0)} />
                        <StyledTab iconPosition="start"
                            icon={<GroupAddIcon />}
                            label="Add Multiple Students" {...a11yProps(1)} />
                        <StyledTab iconPosition="start"
                            icon={<MonetizationOnOutlinedIcon />}
                            label="Class Wise Student Installments" {...a11yProps(2)}
                            component="a"
                            href={classInstallmentTabUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                        <StyledTab iconPosition="start"
                            icon={<FilePresentOutlinedIcon />}
                            label="Import Students Data" {...a11yProps(3)}
                            component="a"
                            href={importStudentsTabUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            />
                            <StyledTab iconPosition="start"
                            icon={<PeopleOutlineOutlinedIcon />}
                            label="View Students" {...a11yProps(4)}
                            component="a"
                            href={`${studentsIndex}/${viewStudents}`}
                            // target="_blank"
                            rel="noopener noreferrer"
                            />
                    </StyledTabs>
                </Paper>
            </Box>
            <Box sx={{ p: 3 }}>
                <CustomTabPanel value={tabs} index={0}>
                    <AddSingleStudentStepper />
                </CustomTabPanel>
                <CustomTabPanel value={tabs} index={1}>
                    <AddMultiStudents />
                </CustomTabPanel>

            </Box>
        </Fragment>


    )
}

export default AddStudents