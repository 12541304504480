import { useQuery } from '@tanstack/react-query';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';

export const useCities = () => {

  const { data: citiesList, isPending: isLoading, error } = useQuery({

    queryKey: ['localities-cities'],
    queryFn: async () => {
      const response = await fetchWrapper('GET', urls.CREATE_LOCALITY_CITIES);
      return response.data?.data;
    }
  });

  return {
    isLoading,
    citiesList,
    error
  };
};
