import { FormControl, Grid, InputLabel, MenuItem, TextField, Typography, Box, Paper, Stack } from '@mui/material'
import { Select } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import ClassSelector from 'src/components/classSelector';
import SectionSelector from 'src/components/sectionSelector';
import SessionSelector from 'src/components/sessionSelector';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import LocalityDropdown from 'src/components/LocalityDropdown';
import { useQuery } from '@tanstack/react-query';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';
import { useSessions } from 'src/hooks/useSessions';
import useUser from 'src/hooks/useUser';
import toast from 'react-hot-toast';
import { useFeeSettings } from 'src/hooks/useFeeSettings';
import { useStates } from 'src/hooks/useStates';
import { useCities } from 'src/hooks/useCities';
import { useLocation } from 'react-router-dom';
import { encodeQueryParams } from 'src/Utils/helperFunc';
import DeleteIcon from '@mui/icons-material/Delete';
import { studentImageBaseURL } from 'src/constants/configs';
// import EditProfileSkeleton from 'src/components/editProfileSkeleten';


function AddNewStudentStep({ handleNext, setstudent }) {

    const { USERDATA: user } = useUser();
    const { sessions: yearSessions } = useSessions();
    const { feeSettings } = useFeeSettings();
    const { statesList } = useStates();
    const { citiesList: citiesListData } = useCities();
    const location = useLocation();


    const [loading, setLoading] = React.useState(false)
    const [uploading, setUploading] = React.useState(false)
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        refNo: '',
        dateOfAdms: new Date().toISOString().substr(0, 10)
    });
    const studentId = location?.state?.id
    const citiesList = formValues.StateId ? citiesListData.filter((el) => el?.State_Id == formValues?.StateId) : citiesListData


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };


    // UPLOAD PROFILE


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            setUploading(true);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                try {
                    const base64Image = reader.result;
                    const response = await fetchWrapper('POST', urls.UPLOAD_FILE, {
                        file: base64Image,
                        fileName: file.name,
                    })

                    setFormValues(prev => ({
                        ...prev,
                        image: response.data.result
                    }));
                } catch (err) {
                    console.log('Failed to upload image. Please try again.');
                } finally {
                    setUploading(false);
                }
            };
        } catch (err) {
            console.log('Failed to read the file.');
            setUploading(false);
        }
    };



    const handleDeleteImage = () => {
        setFormValues(prev => ({
            ...prev,
            image: null
        }));
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        if ((name === 'cellNo' && value) || (name === 'familyCode' && value)) {
            if ((feeSettings?.branchconfigresponse?.familySearch == 'CN') && (name === 'cellNo' && value)) {
                getFamilyDetails({ cellNo: value });

            } else if ((feeSettings?.branchconfigresponse?.familySearch == 'FC') && (name === 'familyCode' && value)) {
                getFamilyDetails({ familyCode: value });

            }
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formValues?.sessionId || !formValues?.classId || !formValues.sectionId) return toast.error('Session, class and section is required');
        setLoading(true)
        const response = await fetchWrapper('POST', urls.ADD_SINGLE_STD, formValues);
        if (response?.data?.data) {
            !Array.isArray(response?.data?.data) && setstudent(response?.data?.data)
            handleNext()
            toast.success(response?.data?.message);
        }
        setLoading(false)


    };


    // REF NO.


    const {
        data: viewRefNo,
    } = useQuery({
        queryKey: ['viewrefno'],
        queryFn: async () => {
            const response = await fetchWrapper('GET', urls.GET_REF_NO);
            if (response.data?.data) {
                setFormValues(prev => ({
                    ...prev,
                    refNo: response.data.data
                }));
            }
            return response.data?.data;
        },
        enabled: Boolean(!studentId)
    });


    // FAMILY DETAILS


    const getFamilyDetails = async (query) => {
        const { data } = await fetchWrapper('POST', urls.GET_FAMILY_DETAILS, { query });
        if (data?.data) {
            setFormValues(prev => ({
                ...prev,
                ...data?.data
            }))
        }
    }

    // EDIT PROFILE


    const {
        data: profileDetails,
        isLoading: fetchingProfile
    } = useQuery({
        queryKey: ['student-profile'],
        queryFn: async () => {
            const queryParams = { id: studentId };
            const queryString = encodeQueryParams(queryParams);
            const { data } = await fetchWrapper('GET', `${urls.STD_PROFILE}?${queryString}`);
            if (data?.status === 200) {
                const studentProfileImage =  data.data?.image ? (data.data?.image.includes('computech-media-content.s3') ? data.data?.image : studentImageBaseURL + data.data?.image) :  null
                setFormValues({...data.data,image:studentProfileImage})
                setstudent(data.data)
            }
            return data?.data;
        },
        enabled: Boolean(studentId)
    })



    useEffect(() => {
        const branchSession = yearSessions.find((sess) => sess.sessionYear === user.sessionYear);
        if (branchSession) {
            setFormValues({
                ...formValues,
                refNo: viewRefNo,
                sessionId: branchSession.sessionId,
                ...(profileDetails && {
                    ...profileDetails
                })
            });

        }

    }, [yearSessions, user, fetchingProfile]);


    // if(fetchingProfile) return <EditProfileSkeleton/>
    return (
        <Fragment>
            <Box component="form" onSubmit={handleSubmit} >
                <Paper sx={{ p: 3, boxShadow: 2 }}>
                    <Typography variant="h6" gutterBottom color="grey.700">
                        Add New Student
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} md={9} spacing={2} direction="row">
                            <Grid item xs={6} md={4}>
                                <TextField
                                    label="Student Name"
                                    name="firstName"
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: !!formValues.firstName
                                    }}
                                    value={formValues.firstName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField
                                    label="Father Name"
                                    name="lastName"
                                    fullWidth
                                    value={formValues.lastName}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.lastName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField
                                    label="Cell No"
                                    fullWidth
                                    required
                                    name="cellNo"
                                    InputLabelProps={{
                                        shrink: !!formValues.cellNo
                                    }}
                                    type='number'
                                    value={formValues.cellNo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}


                                />

                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SessionSelector
                                    upcomingSessions={true}
                                    required={true}
                                    value={formValues.sessionId ?? 0}
                                    onChange={(e) => setFormValues((prev) => ({
                                        ...prev,
                                        sessionId: e.target.value,
                                    }))}
                                />

                            </Grid>
                            <Grid item xs={6} md={4}>
                                <ClassSelector required={true} value={formValues.classId ?? null}
                                    onChange={(e) => setFormValues((prev) => ({
                                        ...prev,
                                        classId: e.target.value,
                                    }))}
                                    shrinkLabel={!!formValues.classId}
                                />

                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SectionSelector
                                    required={true}
                                    toggleAlloption={false} value={formValues.sectionId ?? null}
                                    onChange={(e) => setFormValues((prev) => ({
                                        ...prev,
                                        sectionId: e.target.value,
                                    }))}
                                    shrinkLabel={!!formValues.sectionId}
                                    selectedClass={formValues.classId}
                                />

                            </Grid>
                        </Grid>
                        <Grid
                            style={{ height: '140px' }}
                            container
                            item
                            xs={12}
                            md={3}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    border: '2px dashed silver',
                                    borderRadius: '4px',
                                    width: '50%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                    overflow: 'hidden', 
                                    position: 'relative',
                                }}
                            >
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-picture"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label
                                    htmlFor="upload-picture"
                                    style={{
                                        display: formValues.image ? 'none' : 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        zIndex: 1,
                                    }}
                                >
                                    <UploadFileIcon sx={{ fontSize: 50, color: 'silver' }} />
                                    <Button component="span" sx={{ color: 'silver', fontSize: 12 }}>
                                        {uploading ? 'Uploading...' : 'UPLOAD PICTURE'}
                                    </Button>
                                </label>
                                {formValues.image && (
                                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <img
                                            src={formValues.image}
                                            alt="Uploaded file"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <Button
                                            onClick={handleDeleteImage}
                                            sx={{
                                                position: 'absolute',
                                                top: 5,
                                                right: 5,
                                                color: 'red',
                                                zIndex: 2,
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Accordion sx={{ mt: 4, boxShadow: 2, borderRadius: "6px", '&:before': { height: '0px' } }} elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="h6" gutterBottom color="grey.700">
                            Advance Information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Student Name in Urdu"
                                    name="urduName"
                                    fullWidth
                                    value={formValues.urduName}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.urduName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.gender}>Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Gender"
                                        name="gender"
                                        value={formValues.gender ?? null}
                                        onChange={handleChange}
                                    //   value={configData['defaultPayDate']}
                                    //   onChange={(e) => handleValueChange(e, setConfigData)}
                                    >
                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="B-Form No"
                                    name="formBNo"
                                    fullWidth
                                    value={formValues.formBNo}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.formBNo,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.religion}>Select Religion</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Religion"
                                        name="religion"
                                        value={formValues.religion ?? null}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>Select Religion</MenuItem>
                                        <MenuItem value={"Muslim"}>Muslim</MenuItem>
                                        <MenuItem value={"Hindu"}>Hindu</MenuItem>
                                        <MenuItem value={"Sikh"}>Sikh</MenuItem>
                                        <MenuItem value={"Christian"}>Christian</MenuItem>
                                        <MenuItem value={"Ahmadi"}>Ahmadi</MenuItem>


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Date of Birth"
                                    name="dob"
                                    type="date"
                                    fullWidth
                                    value={formValues.dob}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Place of Birth"
                                    name="placeOfBirth"
                                    fullWidth
                                    value={formValues.placeOfBirth}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.placeOfBirth,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Date of Admission"
                                    name="dateOfAdms"
                                    type="date"
                                    fullWidth
                                    value={formValues.dateOfAdms}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.StateId}>Select State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select State"
                                        name="StateId"
                                        value={formValues.StateId ?? null}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>Select State</MenuItem>
                                        {statesList?.length > 0 && statesList.map((el, index) => (
                                            <MenuItem value={el.State_Id} key={index}>{el.State_Name}</MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.CityId}>Select City</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select State"
                                        name="CityId"
                                        disabled={!formValues.StateId}
                                        value={formValues.CityId ?? null}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>Select City</MenuItem>
                                        {citiesList?.length > 0 && citiesList.map((el, i) => (
                                            <MenuItem value={el.City_Id} key={i}>{el.City_Name}</MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Postal Code"
                                    name="pCode"
                                    fullWidth
                                    value={formValues.pCode}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.pCode,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Cast"
                                    name="cast"
                                    fullWidth
                                    value={formValues.cast}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.cast,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    fullWidth
                                    value={formValues.email}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.email,
                                    }}
                                />
                            </Grid>

                        </Grid>

                        {/* CONTACT INFO */}
                        <Divider sx={{ mt: 4 }} />
                        <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                            Contact Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="WhatsApp No"
                                    name="whatsappNumber"
                                    fullWidth
                                    value={formValues.whatsappNumber}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.whatsappNumber,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father Cell No"
                                    name="fatherCellno"
                                    fullWidth
                                    value={formValues.fatherCellno}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.fatherCellno,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Mother Cell No"
                                    name="motherCell"
                                    fullWidth
                                    value={formValues.motherCell}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.motherCell,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Home Cell No"
                                    name="homeNumber"
                                    fullWidth
                                    value={formValues.homeNumber}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.homeNumber,
                                    }}
                                />
                            </Grid>
                        </Grid>



                        <Divider sx={{ mt: 4 }} />
                        <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                            Academic Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="G.R No"
                                    name="refNo"
                                    fullWidth
                                    type="number"
                                    InputLabelProps={{
                                        shrink: !!formValues.refNo,
                                    }}
                                    value={formValues.refNo}
                                    onChange={handleChange}
                                />
                                {/* based on system settings */}
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Admission Form No"
                                    name="count"
                                    fullWidth
                                    value={formValues.count}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.count,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.medium}>Select Medium</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Requested Medium"
                                        name="medium"
                                        value={formValues.medium ?? null}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"English"}>English</MenuItem>
                                        <MenuItem value={"Urdu"}>Urdu</MenuItem>
                                        <MenuItem value={"Sindhi"}>Sindhi</MenuItem>


                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: 4 }} />

                        <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                            Family Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Family Code"
                                    name="familyCode"
                                    type='number'
                                    fullWidth
                                    value={formValues.familyCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        shrink: !!formValues.familyCode,
                                    }}

                                />
                                {/* based on system settings and fetch family datails then put here*/}
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father CNIC"
                                    name="cnic"
                                    type='number'
                                    fullWidth
                                    value={formValues.cnic}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.cnic,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father Qualification"
                                    name="fatherQualification"
                                    fullWidth
                                    value={formValues.fatherQualification}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.fatherQualification,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father Occupation"
                                    name="fatherOccupation"
                                    fullWidth
                                    value={formValues.fatherOccupation}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.fatherOccupation,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father Business Address"
                                    name="FbusinessAddress"
                                    fullWidth
                                    value={formValues.FbusinessAddress}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.FbusinessAddress,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Father Income"
                                    name="fatherIncome"
                                    fullWidth
                                    value={formValues.fatherIncome}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.fatherIncome,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Mother Name"
                                    name="motherName"
                                    fullWidth
                                    value={formValues.motherName}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.motherName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Mother CNIC"
                                    name="motherCnic"
                                    fullWidth
                                    value={formValues.motherCnic}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.motherCnic,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Mother Qualification"
                                    name="motherQualification"
                                    fullWidth
                                    value={formValues.motherQualification}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.motherQualification,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label" shrink={!!formValues.motherActivity}>Mother Activity</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Mother Activity"
                                        name="motherActivity"
                                        value={formValues.motherActivity ?? null}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>Mother Activity</MenuItem>
                                        <MenuItem value={"Professional"}>Professional</MenuItem>
                                        <MenuItem value={"Housewife"}>Housewife</MenuItem>


                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                formValues.motherActivity == 'Professional' &&
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        label="Mother Designation"
                                        name="motherDesignation"
                                        fullWidth
                                        value={formValues.motherDesignation}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: !!formValues.motherDesignation,
                                        }}
                                    />
                                </Grid>
                            }


                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Home Address"
                                    name="address"
                                    fullWidth
                                    value={formValues.address}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.address,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <LocalityDropdown value={formValues.locality} onSelect={(val) => {
                                    setFormValues(prev => ({
                                        ...prev, locality: val
                                    }))
                                }} />
                            </Grid>
                        </Grid>

                        {/* GUARDIAN INFO */}

                        <Divider sx={{ mt: 4 }} />

                        <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                            Guardian Information <small><em>(this part will be filled in case of death of father)</em></small>
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Name"
                                    name="gardianName"
                                    fullWidth
                                    value={formValues.gardianName}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian CNIC"
                                    name="gardianCnic"
                                    fullWidth
                                    value={formValues.gardianCnic}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianCnic,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Cell No"
                                    name="gardianNo"
                                    fullWidth
                                    value={formValues.gardianNo}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianNo,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Qualification"
                                    name="gardianQualification"
                                    fullWidth
                                    value={formValues.gardianQualification}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianQualification,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Occupation"
                                    name="gardianOccupation"
                                    fullWidth
                                    value={formValues.gardianOccupation}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianOccupation,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Income"
                                    name="gardianIncome"
                                    fullWidth
                                    value={formValues.gardianIncome}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianIncome,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Email"
                                    name="gardianEmail"
                                    fullWidth
                                    value={formValues.gardianEmail}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianEmail,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Guardian Address"
                                    name="gardianAddress"
                                    fullWidth
                                    value={formValues.gardianAddress}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.gardianAddress,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 4 }} />

                        <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                            Admission Test Details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Previous Class"
                                    name="oldClass"
                                    fullWidth
                                    value={formValues.oldClass}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.oldClass,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Total Marks"
                                    name="oldTmarks"
                                    fullWidth
                                    value={formValues.oldTmarks}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.oldTmarks,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Obtaining Marks"
                                    name="oldObtmarks"
                                    fullWidth
                                    value={formValues.oldObtmarks}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.oldObtmarks,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Reason for Previous School Leaving"
                                    name="schoolLeaving"
                                    fullWidth
                                    value={formValues.schoolLeaving}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: !!formValues.schoolLeaving,
                                    }}
                                />
                            </Grid>
                        </Grid>



                    </AccordionDetails>
                </Accordion>
                <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Stack direction="row" sx={{ gap: 1 }}>
                        <Button variant="contained" type='submit' disabled={loading}>
                            {studentId ? 'Update' : 'Save'} & Next
                        </Button>
                    </Stack>
                </Stack>

            </Box>
        </Fragment>
    )
}

export default AddNewStudentStep