import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useLocalities } from 'src/hooks/useLocality';



const LocalityDropdown = ({ value: defaultVal, onSelect }) => {

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(defaultVal || null);

  const { isLoading, localitiesList, error, createLocality, isCreating } = useLocalities();


  useEffect(() => {
    if (localitiesList) {
      const mappedOptions = localitiesList.map((locality) => ({
        label: locality.locality_name,
        value: locality.locality_id,
      }));
      setOptions(mappedOptions);
    }
  }, [localitiesList]);


  const handleCreate = (inputValue) => {
    const newLocality = { locality_name: inputValue };
    createLocality(newLocality, {
      onSuccess: (createdLocality) => {
        const newOption = {
          label: createdLocality.locality_name,
          value: createdLocality.locality_id,
        };
        setOptions((prev) => [...prev, newOption]);
        setValue(newOption);
        newOption?.value ? onSelect(newOption?.value) : onSelect(null)

      },
    });
  };

  const handleChange = (newValue) => {
    setValue(newValue)
    newValue?.value ? onSelect(newValue?.value) : onSelect(null)
  }

  useEffect(() => {
    if (defaultVal) {
      let loc = options.find((el) => el.value == defaultVal)
      setValue(
        loc
      )
    }
  }, [options, defaultVal])


  if (isLoading) return <div>Loading localities...</div>;
  if (error) return <div>Error fetching localities: {error.message}</div>;

  return (
    <CreatableSelect
      classNamePrefix="react-select-container"
      placeholder={'Select Locality'}
      isClearable
      isDisabled={isCreating || isLoading}
      isLoading={isCreating || isLoading}
      onChange={(newValue) => handleChange(newValue)}
      onCreateOption={handleCreate}
      options={options}
      value={value}
    />
  );
};

export default LocalityDropdown;
