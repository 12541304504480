import * as React from 'react';

// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

// icons
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 650,
  bgcolor: 'background.default',
  boxShadow: 24,
  borderRadius: 1
};

export default function CustomModal({ open, onClose, heading, subHeading, customStyle, ...props }) {
  return (
    <Modal open={open} onClose={onClose} {...props}>
      <Box sx={{ ...style, ...customStyle }}>
        <Stack alignItems="flex-end">
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </Stack>
        <Box p={4} pt={0} pr={1}>
          {heading && (
            <Typography variant="h4" textAlign="center">
              {heading}
            </Typography>
          )}
          {subHeading && (
            <Typography paragraph textAlign="center">
              {subHeading}
            </Typography>
          )}
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }} pr={3} mt={1}>
            {props.children}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
