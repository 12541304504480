import { useQuery } from '@tanstack/react-query';

// project imports
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';

export const useClasses = () => {
  const {
    data: classes,
    isPending: isLoading,
    error
  } = useQuery({
    queryKey: ['classes'],
    queryFn: async () => {
      const response = await fetchWrapper('GET', urls.CLASSES);
      return response.data.result;
    }
  });

  return { isLoading, classes, error };
};
