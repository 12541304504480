// mui imports
import { InputAdornment, OutlinedInput } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

// icons
import SearchIcon from '@mui/icons-material/Search';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': {
    width: '100%',
    boxShadow: theme.customShadows.z8,
    backgroundColor: theme.palette.background.paper
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`
  }
}));

function ExpandableSearchbar({ value, onChange, ...props }) {
  return (
    <StyledSearch
      placeholder="Search student..."
      size="small"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        </InputAdornment>
      }
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

export default ExpandableSearchbar;
