import React, { useEffect, useState } from 'react';

// @mui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

// custom imports
import { useClasses } from 'src/hooks/useClasses';

function SectionSelector({ selectedClass, value, onChange, label = 'Section',required = false, shrinkLabel = false, toggleAlloption = true, ...props }) {
    const { classes, isLoading: classesLoading } = useClasses();
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (selectedClass && selectedClass !== 'all' && classes) {
            setSections(classes[selectedClass].SectionDetails);
        }
    }, [selectedClass, classes]);

    if (classesLoading) {
        return <Skeleton variant="rectangular" width="100%" height="20px" animation="wave" />;
    }
    return (
        <FormControl fullWidth disabled={selectedClass === 'all'} required={required}>
            {
                shrinkLabel ?
                    <InputLabel id="demo-simple-select-label" shrink={shrinkLabel}>{label}</InputLabel>
                    :
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            }
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label={label} value={value} onChange={onChange} {...props}>
                {toggleAlloption && <MenuItem value="all">All</MenuItem>}
                {selectedClass &&
                    selectedClass !== 'all' &&
                    sections.map((section) => {
                        return (
                            <MenuItem key={section.sectionId} value={section.sectionId}>
                                {section.sectionName}
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
}

export default SectionSelector;
