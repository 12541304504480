import {
  accessDenied,
  attendanceIndex,
  blankAttendanceSheet,
  classwiseFeeUpdate,
  editAttendance,
  feeSettigs,
  feesIndex,
  headsSettings,
  voucher,
  headwiseReport,
  userProfitLostReport,
  installmentStudents,
  multiMonthsFeeReport,
  studentSearchWithFee,
  totalDatewiseFeeReport,
  arrearsReport,
  userwiseFeeReport,
  datewiseFeeCollectionReport,
  attendanceReports,
  dayAttendanceSummary,
  datewiseAttendanceReport,
  feeRecievableReport,
  instituteAttendanceSummary,
  familiesFeeReport,
  filledAttendanceSheet,
  mostAbsentees,
  dailyAttendanceReport,
  studentsIndex,
  studentProfile,
  struckOffStudentsList,
  viewStudents
} from 'src/constants/configs/pagesRoutesText';

export function additionalPrivileges(FLAT_PRIVILEGES) {
  const newArray = [];
  newArray.push(accessDenied);

  // Attendance
  if (FLAT_PRIVILEGES.includes(attendanceIndex)) {
    newArray.push(
      `${blankAttendanceSheet}`,
      `${attendanceIndex}/${editAttendance}`,
      `${attendanceIndex}/${attendanceReports}`,
      `${attendanceIndex}/${dayAttendanceSummary}`,
      `${attendanceIndex}/${datewiseAttendanceReport}`,
      `${attendanceIndex}/${instituteAttendanceSummary}`,
      `${attendanceIndex}/${filledAttendanceSheet}`,
      `${attendanceIndex}/${mostAbsentees}`,
      `${attendanceIndex}/${dailyAttendanceReport}`,
    );
  }

  // Fees
  if (FLAT_PRIVILEGES.includes(feesIndex)) {
    newArray.push(
      `${feesIndex}/${voucher}`,
      `${feesIndex}/${headsSettings}`,
      `${feesIndex}/${classwiseFeeUpdate}`,
      `${feesIndex}/${feeSettigs}`,
      `${feesIndex}/${feeRecievableReport}`,
      `${feesIndex}/${headwiseReport}`,
      `${feesIndex}/${userProfitLostReport}`
    );
    newArray.push(
      `${feesIndex}/${installmentStudents}`,
      `${feesIndex}/${multiMonthsFeeReport}`,
      `${feesIndex}/${studentSearchWithFee}`,
      `${feesIndex}/${totalDatewiseFeeReport}`,
      `${feesIndex}/${datewiseFeeCollectionReport}`,
      `${feesIndex}/${arrearsReport}`,
      `${feesIndex}/${userwiseFeeReport}`,
      `${feesIndex}/${familiesFeeReport}`,
    );
  }

  if(FLAT_PRIVILEGES.includes(studentsIndex)){
    newArray.push(
      `${studentsIndex}/${studentProfile}`,
      `${studentsIndex}/${struckOffStudentsList}`,
      `${studentsIndex}/${viewStudents}`,
    )
  }
  return newArray;
}
