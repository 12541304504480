import React, { useState } from "react";
import {
  Stack,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Chip,
  Select,
  FormControl
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import palette from "src/@core/theme/palette";


const MultiSelectionDropdown = ({ values, onDelete, onChange, fields }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Add More Fields</InputLabel>
      <Select
        multiple
        value={values}
        onChange={onChange}
        input={<OutlinedInput label="Multiple Select" />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                style={{
                  background: palette.primary.main,
                  color: "#fff"
                }}
                // label={value}
                label={fields.find(f => f.value === value).label}
                onDelete={()=>onDelete(value)}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
      >
        {fields.map((name) => (
          <MenuItem
            key={name}
            value={name.value}
            sx={{ justifyContent: "space-between" }}
          >
            {name.label}
            {values.includes(name) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectionDropdown