import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Typography, Grid, TextField, Button, IconButton } from '@mui/material';
import ClassSelector from 'src/components/classSelector';
import SectionSelector from 'src/components/sectionSelector';
import SessionSelector from 'src/components/sessionSelector';
import DeleteIcon from '@mui/icons-material/Delete';
import MultiSelectionDropdown from 'src/components/multipleSelectionDropdown';
import toast from 'react-hot-toast';
import useUser from 'src/hooks/useUser';
import { useSessions } from 'src/hooks/useSessions';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';

function AddMultiStudents() {
    const { USERDATA: user } = useUser();
    const { sessions: yearSessions } = useSessions();

    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        sessionId: '',
        classId: '',
        sectionId: ''
    });


    useEffect(() => {
        const branchSession = yearSessions.find((sess) => sess.sessionYear === user.sessionYear);
        if (branchSession) {
            setFormValues({
                ...formValues,
                sessionId: branchSession.sessionId
            });
        }

    }, [yearSessions, user]);

    const [rows, setRows] = useState(3);
    const [dynamicRows, setDynamicRows] = useState([{
        firstName: '',
        lastName: '',
        cellNo: ""
    },
    {
        firstName: '',
        lastName: '',
        cellNo: ""
    },
    {
        firstName: '',
        lastName: '',
        cellNo: ""
    }]);
    const requiredFields = ['firstName', 'cellNo'];

    const [selectedFields, setSelectedFields] = useState([
        'firstName',
        'lastName',
        'cellNo'
    ]);

    const allFields = [
        { value: 'firstName', label: 'Student Name', required: true },
        { value: 'lastName', label: 'Father Name' },
        { value: 'cellNo', label: 'Cell', required: true, type: 'number' },
        { value: 'pCode', label: 'Postal Code' },
        { value: 'gardianEmail', label: 'Email' },
        { value: 'motherCell', label: 'Mother Cell', type: 'number' },
        { value: 'cnic', label: 'CNIC', type: 'number' }
    ];

    const handleDynamicChange = (index, field, value) => {
        const updatedRows = dynamicRows.map((row, i) => (
            i === index ? { ...row, [field]: value } : row
        ));
        setDynamicRows(updatedRows);
    };

    const handleAddRows = () => {
        const newRow = {};
        selectedFields.forEach(field => newRow[field] = '');
        setDynamicRows([...dynamicRows, newRow]);
        setRows(rows + 1);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = dynamicRows.filter((_, i) => i !== index);
        setDynamicRows(updatedRows);
        setRows(rows - 1)
    };

    const clear = () => {
        setFormValues({
            firstName: '',
            lastName: '',
            cellNo: ""
        })
        setDynamicRows([{
            firstName: '',
            lastName: '',
            cellNo: ""
        }])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues?.sessionId || !formValues?.classId || !formValues.sectionId) return toast.error('Session, class and section is required');

        const students = dynamicRows.map(row => ({
            sessionId: formValues.sessionId,
            classId: formValues.classId,
            sectionId: formValues.sectionId,
            ...row
        }));
        setLoading(true)
        const response = await fetchWrapper('POST', urls.ADD_BULK_STDS, { students });
        setLoading(false)
        if (response?.data?.data) {
            clear()
            toast.success(response?.data?.message);
        }
    };

    const handleFieldChange = (event) => {
        const { target: { value } } = event;
        let val = typeof value === 'string' ? value.split(',') : value
        const withRequiredFields = [...new Set([...requiredFields,...val])];
        setSelectedFields(withRequiredFields)

    };

    const handleFieldDelete = (value) => {
        setSelectedFields(
            selectedFields.filter((item) => requiredFields.includes(item) ? item : item !== value)
        )
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h5" gutterBottom color="grey.700">
                Add Bulk Students
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <SessionSelector
                            value={formValues.sessionId}
                            upcomingSessions={true}
                            required={true}
                            onChange={(e) => setFormValues((prev) => ({
                                ...prev,
                                sessionId: e.target.value,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ClassSelector
                            required={true}
                            value={formValues.classId}
                            onChange={(e) => setFormValues((prev) => ({
                                ...prev,
                                classId: e.target.value,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <SectionSelector
                            required={true}
                            value={formValues.sectionId}
                            onChange={(e) => setFormValues((prev) => ({
                                ...prev,
                                sectionId: e.target.value,
                            }))}
                            selectedClass={formValues.classId}
                            toggleAlloption={false}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="contained" sx={{ m: 1 }} color="primary" onClick={handleAddRows}>
                            Add Rows
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MultiSelectionDropdown values={selectedFields}
                            onChange={handleFieldChange} onDelete={handleFieldDelete} fields={allFields} />
                    </Grid>
                </Grid>

                {dynamicRows.map((row, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
                        {selectedFields.map((field) => (
                            <Grid item xs={6} md={3} key={field}>
                                <TextField
                                    label={allFields.find(f => f.value === field).label}
                                    fullWidth
                                    type={allFields.find(f => f.value === field)?.type ?? "text"}
                                    required={allFields.find(f => f.value === field)?.required ? true : false}
                                    value={row[field]}
                                    onChange={(e) => handleDynamicChange(index, field, e.target.value)}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12} md={1}>
                            <IconButton onClick={() => handleDeleteRow(index)} color="primary">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="primary" disabled={dynamicRows.length == 0 || loading}>
                        Submit
                    </Button>
                </Grid>
            </form>
        </Box>
    )
}

export default AddMultiStudents