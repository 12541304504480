import React from 'react';
import PropTypes from 'prop-types';

// @mui
import Typography from '@mui/material/Typography';

const Heading = ({ title, centered, sx }) => {
  return (
    <Typography variant="h4" component="h1" sx={{ color: 'grey.700', ...sx }} textAlign={centered && 'center'}>
      {title}
    </Typography>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  centered: PropTypes.bool
};

export default Heading;
