import React from 'react';
import moment from 'moment';

// @mui
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const MonthSelector = ({ valueType = 'number', label='Month', onChange, value, disabledMonths = [], ...props }) => {
  const generateMonthsArray = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const month = moment().month(i);
      months.push({
        value: i,
        label: month.format('MMMM'),
        disabled: disabledMonths.includes(i)
      });
    }
    return months;
  };

  const months = generateMonthsArray();

  const handleChange = (event) => {
    const selectedMonth = event.target.value;

    if (months[selectedMonth].disabled) {
      return;
    }

    let value;
    if (valueType === 'number') {
      value = selectedMonth; // Return the month number
    } else if (valueType === 'name') {
      value = months[selectedMonth].label; // Return the month name
    }
    // Call the onChange prop if it's passed from parent component
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        {...props}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 250
            }
          }
        }}
        onChange={handleChange}
      >
        {months.map((month) => (
          <MenuItem key={month.value} value={month.value} disabled={month.disabled}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MonthSelector;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export function MultiMonthSelector({ value, setValue, onChange, ...props }) {
  const monthNames = moment.months();

  const handleChange = (event) => {
    const {
      target: { value: selectedValues }
    } = event;

    setValue(selectedValues);
    onChange(selectedValues);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multi-month-selector-label">Months</InputLabel>
      <Select
        labelId="multi-month-selector-label"
        id="multi-month-selector"
        multiple
        value={value}
        onChange={handleChange}
        MenuProps={MenuProps}
        input={<OutlinedInput label="Months" />}
        renderValue={(selected) => selected.map((index) => monthNames[index]).join(', ')}
        {...props}
      >
        {monthNames.map((name, index) => (
          <MenuItem key={name} value={index}>
            <Checkbox checked={value.includes(index)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
