import { Button, Paper } from '@mui/material'
import React, { useState } from 'react'
import FamilyCreateModal from './FamilyCreateModal';
import { StyledDataGrid } from 'src/components/styleDataGrid/style';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';
import { useQuery } from '@tanstack/react-query';

function SiblingInfoTab({studentData}) {
  const {cellNo} = studentData

  const [dataModal, setdataModal] = useState(false);
  const [studenData,setStudentData]=useState({})

  const { isLoading } = useQuery({
      queryKey: ['family-info'],
      queryFn: async () => {
        const {data} = await fetchWrapper('GET', `${urls.STD_FAMILY_INFO}/${cellNo}`);

        if (data?.status === 200) {
          setStudentData(data?.data)
        }
        return data;
      },
      enabled: Boolean(cellNo)
    });

  const columns = [
    {
      field: 'candidateRollNo',
      headerName: 'G.R No',
      minWidth: 90
    },
    {
      minWidth: 130,
      flex: 1,
      headerName: 'Name',
      field: 'firstName',
      renderCell: ({ row }) => {
        const { firstName} = row;
        return `${firstName}`;
      }
    },   
    {
      field: 'lastName',
      headerName: 'Father Name',
      minWidth: 120,
      flex: 1,
    },
    { field: 'classinfo', headerName: 'Class', minWidth: 120, flex: 1,
      renderCell: ({ row }) => {
        const { classinfo } = row;
        return classinfo?.ClassName
      }
     },
    { field: 'sectioninfo', headerName: 'Section', minWidth: 120, flex: 1,
      renderCell: ({ row }) => {
        const { sectioninfo } = row;
        return sectioninfo?.sectionName
      }
     },

    {
      field: 'cellNo',
      headerName: 'Cell',
      minWidth: 120,
      flex: 1
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      minWidth: 120,
      flex: 1
    }
  ]

  return (
    <Paper elevation={4} sx={{ p: 3, with: "100%" }} >

      <Button sx={{ display: { marginBottom: '1rem'} }} onClick={() => setdataModal(true)} variant="contained">

      Create Parents IDs
      </Button>

      <FamilyCreateModal
        open={dataModal}
        onClose={() => setdataModal(false)}
        studentData={studentData}
      />
      <StyledDataGrid
        autoHeight
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        columns={columns}
        disableColumnMenu
        disableColumnFilter
        loading={isLoading}
        hideFooter
        rows={studenData}
        getRowId={(row) => row.candidateRollNo}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } }
        }}
      />

    </Paper>
  )
}

export default SiblingInfoTab