import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';

export const useLocalities = () => {
  const queryClient = useQueryClient();
  const [localitiesList, setLocalitiesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch data only if not available in state
  useEffect(() => {
    const fetchLocalities = async () => {
      if (localitiesList.length === 0) {
        setIsLoading(true);
        try {
          const response = await fetchWrapper('GET', urls.GET_LOCALITIES);
          setLocalitiesList(response.data?.data);
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchLocalities();
  }, [localitiesList]);

  const { mutate: createLocality, isLoading: isCreating, error: createError } = useMutation({
    mutationFn: async (newLocality) => {
      const response = await fetchWrapper('POST', urls.CREATE_LOCALITY, newLocality);
      return response.data?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['localities']);
      // Re-fetch localities to update state
      // queryClient.fetchQuery(['localities']);
    }
  });

  // Function to get locality label by number
  const getLocalityLabel = (localityNumber) => {
    const locality = localitiesList.find((loc) => loc.locality_id === Number(localityNumber));
    return locality ? locality?.locality_name : '--';
  };

  return {
    isLoading,
    localitiesList,
    error,
    createLocality,
    isCreating,
    createError,
    getLocalityLabel, // Function to get locality label by number
  };
};
