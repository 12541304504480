import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_FEE_SETTINGS_HANDLER } from 'src/redux/actions/feesActions';
import useUser from './useUser';

export const useFeeSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const feeSettings = useSelector((state) => state.FEE_SETTINGS);
  // hook
  const dispatch = useDispatch();
  const { USERDATA: user } = useUser();

  useEffect(() => {
    if (Object.keys(feeSettings).length === 0) {
      const additionalOption = {
        params: {
          branch_id: user.branchID
        }
      };
      setIsLoading(true);
      updateSettings(additionalOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add an empty dependency array to run the effect only once

  function updateSettings(additionalOption) {
    dispatch(GET_FEE_SETTINGS_HANDLER(additionalOption))
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }
  return { isLoading, feeSettings, error, updateSettings };
};
