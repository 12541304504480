import { BASE_URL } from 'src/constants/configs/index';

const urls = {
  GETUSERSLIST: `${BASE_URL}/user/getUsers`,
  STUDENT: `${BASE_URL}/students`,
  CLASSES: `${BASE_URL}/classes`,
  SESSIONS: `${BASE_URL}/sessions`,
  MARKABLE_STUDENTS: `${BASE_URL}/attendance/students`,
  LOGIN: `${BASE_URL}/auth/login`,
  USER_PRIVILEGES: `${BASE_URL}/auth/privileges`,
  SMS: `${BASE_URL}/user/smsdetails`,
  MARK_ATTENDANCE: `${BASE_URL}/attendance/attendance`,
  ATTENDANCE: `${BASE_URL}/attendance`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  NOTIFICATION: `${BASE_URL}/user/notifications`,
  EDIT_ATTENDANCE: `${BASE_URL}/attendance/attendance`,
  DELETE_ATTENDANCE: `${BASE_URL}/attendance/attendance`,
  MARK_HOLIDAY: `${BASE_URL}/attendance/holiday`,
  ABSENTEES_STUDENTS: `${BASE_URL}/attendance/absentStudents`,
  SEND_SMS: `${BASE_URL}/attendance/sendMessages`,
  GET_DATEWISE_ATTENDANCE: `${BASE_URL}/attendance/dateWiseFeeCollection`,
  FILLED__ATTENDANCE_SHEET: `${BASE_URL}/attendance/filledAttendanceSummary`,
  GET_DAILY_ATTENDANCE_REPORT: `${BASE_URL}/attendance/dailyAttendancesReport`,
  INSTITUTE_ATTENDANCE_SUMMARY: `${BASE_URL}/attendance/instituteattendanceSummary`,
  GET_DATEWISE_ATTENDANCE_REPORT: `${BASE_URL}/attendance/dateWiseAttendance`,
  GET_MOST_ABSENTEES_REPORT: `${BASE_URL}/attendance/mostabsentees`,
  GET_DAY_ATTENDANCE_SUMMARY: `${BASE_URL}/attendance/dayAttendanceReport`,
  SEND_DATEWISE_ATTENDANCE_REPORT_SMS: `${BASE_URL}/attendance/dateWiseAttendanceSms`,

  // FEES
  CLASSWISE_CHALLAN: `${BASE_URL}/challan/classchallans`,
  DELETE_CHALLAN: `${BASE_URL}/challan`,
  CHALLAN_SMS: `${BASE_URL}/challan/sendfeesms`,
  FEE_HEADS: `${BASE_URL}/challan/feeheads`,
  GET_STUDENTS_CHALLAN: `${BASE_URL}/challan/students`,
  GENERATE_CHALLAN: `${BASE_URL}/challan`,
  GET_SINGLE_CHALLAN: `${BASE_URL}/challan`,
  EDIT_SINGLE_CHALLAN: `${BASE_URL}/challan`,
  GET_PAYMENT_DETAILS: `${BASE_URL}/challan/paymentdetails`,
  SAVE_PAYMETNS: `${BASE_URL}/challan/payfee`,
  UNPAY_PAYMENT: `${BASE_URL}/challan/removepayment`,
  Family_Voucher: `${BASE_URL}/challan/familyvoucher`,
  FEE_SETTINGS: `${BASE_URL}/challan/feeSettings`,
  UPDATE_FEE_SETTINGS: `${BASE_URL}/challan/updateFeeSettings`,
  GET_CLASSWISE_FEE_STUDENTS: `${BASE_URL}/challan/getClassWiseFee`,
  POST_CLASSWISE_FEE_UPDATE: `${BASE_URL}/challan/addFeeStructure`,
  GET_HEADWISE_REPORT: `${BASE_URL}/challan/headwise`,
  VIEWINSTALLMENTSTUDENTS: `${BASE_URL}/challan/viewInstallment`,
  GETMULTIMONTHREPORT: `${BASE_URL}/challan/multiMonthReport`,
  GETSTUDENTFEEREPORT: `${BASE_URL}/challan/studentFeeReport`,
  GET_USER_WISE_PROFIT_LOSS: `${BASE_URL}/challan/userWiseProfitLoss`,
  DATE_WISE_FEE_COLLECTION_REPORT: `${BASE_URL}/challan/dateWiseFeeCollectionReport`,
  GET_ARREARS_REPORTS: `${BASE_URL}/challan/arrearFeeeports`,
  GET_USER_WISE_FEE_REPORTS: `${BASE_URL}/challan/userWiseFeeCollectionReport`,
  GET_DATEWISE_FEE_COLLECTION: `${BASE_URL}/challan/dateWiseFeeCollection`,
  SEND_ARREARS_SMS: `${BASE_URL}/challan/sendArrearSms`,
  DELETE_INSTALLMENT_CHALLANS: `${BASE_URL}/challan/deleteChallanInstallment`,
  DELETE_INSTALLMENT: `${BASE_URL}/challan/deleteInstallment`,
  GET_FEE_RECEIVABLE: `${BASE_URL}/challan/feeReceiveableReport`,
  GET_FAMILY_REPORT: `${BASE_URL}/challan/familiesreports`,
  SEND_FAMILY_FEE_SMS: `${BASE_URL}/challan/familiesreportsSms`,

  // STUDENTS
  ADD_SINGLE_STD: `${BASE_URL}/students/create`,
  ADD_BULK_STDS: `${BASE_URL}/students/bulkcreate`,
  STD_FEE_PLAN:`${BASE_URL}/students/stdfeeplan`,
  GET_STD_FEE_PLAN:`${BASE_URL}/students/getstdfeeplan`,
  STD_PROFILE: `${BASE_URL}/students/profile`,
  STD_FAMILY_INFO:`${BASE_URL}/students/familyinfo`,
  STUCK_OFF:`${BASE_URL}/students/struckoff`,
  VIEW_STUDENTS: `${BASE_URL}/students/viewstudents`,
  GET_REF_NO: `${BASE_URL}/students/viewrefno`,
  GET_FAMILY_DETAILS: `${BASE_URL}/students/familyDetails`,

  // UPLOAD FILE

  UPLOAD_FILE: `${BASE_URL}/user/upload`,

  

  // PARENTS
  CREATE_FAMILY: `${BASE_URL}/parents`,

  // LOCALITY

  GET_LOCALITIES: `${BASE_URL}/locality`,
  CREATE_LOCALITY: `${BASE_URL}/locality/create`,
  CREATE_LOCALITY_STATES: `${BASE_URL}/locality/states`,
  CREATE_LOCALITY_CITIES: `${BASE_URL}/locality/cities`,




};

export default urls;
