import { Grid, Typography } from '@mui/material';

const EduInfoTab = ({ studentData }) => {
    let eduInfo = [
        { label: 'GR. No', value: studentData?.rollNumber },
        { label: 'Session', value: studentData['SessionConfig.sessionYear'] },
        { label: 'Class', value: studentData['classinfo.ClassName'] },
        { label: 'Section', value: studentData['sectioninfo.sectionName'] }
      ];
    
      let contactInfo = [
        { label: 'Cell No', value: studentData?.cellNo },
        { label: 'Whatsapp No', value: studentData?.whatsappNumber },
        { label: 'Father Cell No', value: studentData?.fatherCellno },
        { label: 'Mother Cell No', value: studentData?.motherCell },
        { label: "Home No", value: studentData?.homeNumber }
      ];
    return (
        <Grid>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Educational Info
            </Typography>
            <Grid container spacing={2}>
                {
                    eduInfo.map((el) => (
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                                <strong>{el.label}:</strong>&nbsp; {el.value || '--'}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 6, mb: 2 }}>
                Contact Information
            </Typography>
            <Grid container spacing={2}>
                {
                    contactInfo.map((el) => (
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                                <strong>{el.label}:</strong>&nbsp; {el.value || '--'}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default EduInfoTab;
