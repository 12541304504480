import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// custom
import Modal from 'src/components/modal';
import { Grid, Typography, Box } from '@mui/material';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
// import { studentsIndex, viewStudents } from 'src/constants/configs/pagesRoutesText';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { useSessionKey } from 'src/hooks/useSessionKey';


const StruckOffModal = ({ open, onClose,studentData }) => {
    const location = useLocation();
    const key = useSessionKey();


    
    const studentId = location?.state?.id

    const types = [
        { value: 'Completed', label: 'Class/Course Completed' },
        { value: 'Migration', label: 'Migration' },
        { value: 'Miss Behaviour', label: 'Miss Behaviour' },
        { value: 'Fee Defaulter', label: 'Fee Defaulter' },
        { value: 'Regular Absents', label: 'Regular Absents' },
        { value: 'Duplicate Entry', label: 'Duplicate Entry' },
        { value: 'Wrong Entry', label: 'Wrong Entry' },
        { value: 'other', label: 'Other' }
    ];

    const [loading,setLoading]=useState(false)
    const [formValues, setFormValues] = useState({
        expelReason: '',
        sendSMS: ''
    });

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit =async (e) => {
        
        e.preventDefault();
        if(!formValues?.expelReason) return toast.error('Reason is required');
        if(!studentId) return toast.error('Student ID is required');
        setLoading(true)
        const response = await fetchWrapper('POST', urls.STUCK_OFF, {students:[{...formValues,studentId}]});
        if (response?.data?.data) {
            onClose()
            // navigate(`${studentsIndex}/${viewStudents}`)
            window.location.href = externalLinkGenerator(`/old_student_profile.php`, {t:'8vk4ke9kaqmcd4im8fcreo6lq88vk4ke9kaqmcd4im8fcreo6lq88vk4ke9kaqmcd4im8fcreo6lq88vk4ke9kaqmcd4im8fcreo6lq88vk4ke9kaqmcd4im8fcreo6lq8', key,student:studentData.studentId,deleteLocalStorage:'delete' })
            toast.success(response?.data?.message);
          }
          setLoading(false)
        
    };
    return (
        <Modal heading="Stuck Off" open={open} onClose={onClose} customStyle={{ maxWidth: 1280 }}>
            <Box component="form" onSubmit={handleSubmit} p={4}>
                <Typography variant="h6" gutterBottom>
                    Reason of Struck Off
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Type"
                            name="expelReason"
                            value={formValues.expelReason}
                            onChange={handleChange}
                            required
                        >
                            {types.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Send SMS"
                            name="sendSMS"
                            value={formValues.sendSMS}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="YES">YES</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Stuck off Date"
                            name="expelDate"
                            type="date"
                            required
                            fullWidth
                            value={formValues.expelDate}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </Grid>
                    {
                        formValues.type == 'other' &&
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Reason"
                                name="expelReason"
                                value={formValues.expelReason}
                                onChange={handleChange}
                                multiline
                                rows={4}
                                required
                            />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default StruckOffModal;
