import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CustomDatePicker({ fullWidth, label, sx, format, disabled, value, onChange, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker sx={{ width: fullWidth && '100%', ...sx }} label={label} format={format} disabled={disabled} value={value} onChange={onChange} {...props} />
    </LocalizationProvider>
  );
}
