import { Grid, Typography } from '@mui/material';
import { useLocalities } from 'src/hooks/useLocality';

const FamilyInfoTab = ({ studentData }) => {
  const { getLocalityLabel } = useLocalities();

    let familyInfo = [
        { label: 'Family Code', value: studentData?.familyCode },
        { label: 'Father Name', value: studentData?.lastName },
        { label: 'Father CNIC No', value: studentData?.cnic },
        { label: 'Father Qualification', value: studentData?.fatherQualification },
        { label: 'Father Occupation', value: studentData?.fatherOccupation },
        { label: 'Father Business Address', value: studentData?.FbusinessAddress },
        { label: 'Father Income', value: studentData?.fatherIncome },
        { label: 'Mother Name', value: studentData?.motherName },
        { label: 'Mother CNIC', value: studentData?.motherCnic },
        { label: 'Mother Qualification', value: studentData?.motherQualification },
        { label: 'Mother Activity', value: studentData?.motherActivity },
        { label: 'Mother Designation', value: studentData?.motherDesignation },
        { label: 'Home Address', value: studentData?.address },
        { label: 'Locality', value: getLocalityLabel(studentData?.locality) }
    ];

    let guardianInfo = [
        { label: 'Guardian Name', value: studentData?.gardianName },
        { label: 'Guardian Cell No', value: studentData?.gardianNo },
        { label: 'Guardian Qualification', value: studentData?.gardianQualification },
        { label: 'Guardian Occupation', value: studentData?.gardianOccupation },
        { label: "Guardian Income", value: studentData?.gardianIncome },
        { label: "Guardian CNIC", value: studentData?.gardianCnic },
        { label: "Guardian Email", value: studentData?.gardianEmail },
        { label: "Guardian Address", value: studentData?.gardianAddress }
    ]
    return (
        <Grid>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Family Info
            </Typography>
            <Grid container spacing={2}>
                {
                    familyInfo.map((el) => (
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                                <strong>{el.label}:</strong>&nbsp; {el.value || '--'}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 6, mb: 2 }}>
            Guardian Information
            </Typography>
            <Grid container spacing={2}>
                {
                    guardianInfo.map((el) => (
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                                <strong>{el.label}:</strong>&nbsp; {el.value || '--'}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default FamilyInfoTab;
