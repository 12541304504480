import { Grid, TextField, Typography, Box, FormControl, InputLabel, MenuItem, Stack } from '@mui/material'
import React, { Fragment, useState } from 'react'
import Button from '@mui/material/Button';
import MonthSelector from 'src/components/monthSelector';
import Select from '@mui/material/Select';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import urls from 'src/constants/configs/routApis';
// import { useFeeHeads } from 'src/hooks/useFeeHeads';
import CustomDatePicker from 'src/components/datePicker';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SessionSelector from 'src/components/sessionSelector';
import { useSessions } from 'src/hooks/useSessions';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { encodeQueryParams } from 'src/Utils/helperFunc';
import { useFeeSettings } from 'src/hooks/useFeeSettings';

const paymentMods = [{ value: 0, label: "Monthly" }, { value: 1, label: "Installment" }]

function AddFeePlanStep({ handleNext, student }) {

    const { currentSession } = useSessions();
    const { feeSettings } = useFeeSettings();

    const location = useLocation();
    const navigate = useNavigate();
    

    // const { feeHeads } = useFeeHeads();
    // const navigate = useNavigate();


    const [formValues, setFormValues] = useState({
        studentId: student?.studentId,
        std_tution_fee: 0,
        fee_type: 0,
        std_transport_fee: 0,
        std_outstanding_blc: 0,
        std_misc_fee: 0
    });
    const [loading, setLoading] = useState(false)
    const [installmentData, setInstallmentData] = useState([]);

    const studentId = location?.state?.id


    // CHALLAN GENERATION ON HOLD
    // const [dueDate, setDueDate] = useState(moment());
    // const [voucherMonth, setVoucherMonth] = useState(null)
    // const [feeHeadsData, setFeeHeadsData] = useState(feeHeads.map(feeHead => ({
    //     feetype_id: feeHead.feetype_id,
    //     fee_amount: ''
    // })));

    // challan genration on hold
    // useEffect(() => {
    //     if (feeHeads.length > 0) {
    //         setFeeHeadsData(feeHeads.map(feeHead => ({
    //             feetype_id: feeHead.feetype_id,
    //             fee_amount: ''
    //         })))
    //     }
    // }, [feeHeads])

    // challan genration on hold
    // const handleFeeInputChange = (index, value) => {
    //     const updatedFeeHeadsData = [...feeHeadsData];
    //     if (updatedFeeHeadsData[index]) {
    //         updatedFeeHeadsData[index].fee_amount = value;
    //     }
    //     setFeeHeadsData(updatedFeeHeadsData);
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!student) return toast.error('Student not created yet')

        const isInstallmentStd = formValues.fee_type == 1;

        if (!isInstallmentStd && Number(formValues.std_tution_fee) <= 0) return toast.error('Tuition fee cannot be empty and requires a numeric value')
        const invalidInstallment = installmentData.length == 0 ? true : installmentData.some(item => {
            return item.month_id == null ||
                !item.session_id ||
                !item.installment
        }

        );

        if (isInstallmentStd && invalidInstallment) return toast.error('Installment fee or month/session cannot be null be empty')

        // let challanDetails = {
        //     challan_duedate: isInstallmentStd ? installmentData[0].due_date : dueDate,
        //     classs_id: student.classId,
        //     month_id: isInstallmentStd ? installmentData[0].month_id : voucherMonth,
        //     preview: true,
        //     session_id: isInstallmentStd ? installmentData[0].session_id : student.sessionId,
        //     challansData: [
        //         {
        //             month_id: isInstallmentStd ? installmentData[0].month_id : voucherMonth,
        //             challan_duedate: isInstallmentStd ? installmentData[0].due_date : dueDate,
        //             classs_id: student.classId,
        //             fee_remarks: isInstallmentStd ? "Installment Fee" : "Monthly Fee",
        //             misc_fee: formValues.std_misc_fee,
        //             session_id: isInstallmentStd ? installmentData[0].session_id : student.sessionId,
        //             student_id: student.studentId,
        //             transportfee: formValues.std_transport_fee,
        //             tutionfee: formValues.std_tution_fee,
        //             details: feeHeadsData,
        //             collected_amount: "0",
        //             balance: "0"
        //         }
        //     ]
        // } callan generation on hold
        setLoading(true)
        const { data } = await fetchWrapper('POST', urls.STD_FEE_PLAN, {
            feeDetails: formValues,
            ...(formValues.fee_type == 1 && { installmentData }),
            // ...((feeHeadsData.some(fee => fee.fee_amount !== '') && voucherMonth !== null) && {
            //     challanDetails
            // }) callan generation on hold
        });
        if (data.status == 200) {
            // callan generation on hold
            // if (data?.data?.status == 200) {
            //     handlePrint(data.data?.result[0], data?.fineSettings ?? null)
            // }
            data?.user && navigate(`/students/student-profile`, {
                state: {
                  id: data.user
                }
              })
            toast.success(data.message)
        } else {
            toast.error(toast.message)
        }
        setLoading(false)
    };


    // callan generation on hold
    // async function handlePrint(feeDetails, fineSettings) {
    //     const route = `${routeConsts.feesIndex}/${routeConsts.voucher}`;

    //     navigate(route, {
    //         state: {
    //             copies: ['Student copy'],
    //             student: [{
    //                 first_name: student.firstName,
    //                 last_name: student.lastName,
    //                 Class_Name: student.classId,
    //                 section_name: student.sectionId,
    //                 candidate_roll_no: student.candidateRollNo,
    //                 cell_no: student.cellNo,
    //                 family_code: student.familyCode,
    //                 current_tutionfee: feeDetails.tutionfee,
    //                 ...feeDetails, paymentDetails: { paying_date: null }
    //             }],
    //             heads: feeHeads.filter((head) => head.status),
    //             extraFeeDetails: feeDetails,
    //             fineSettings,
    //             voucherType: 'individual'
    //         }
    //     });
    // }


    const handleInstallmentCountChange = (e) => {
        const count = Number(e.target.value);
        setFormValues((prev) => ({
            ...prev,
            no_installment: count,
        }))

        const rawInstallmentAmount = formValues?.annual_package / count;

        const installmentAmount =
            rawInstallmentAmount % 1 !== 0 ? rawInstallmentAmount.toFixed(2) : rawInstallmentAmount;
        const installments = Array.from({ length: count }, (_, index) => ({
            installment: installmentAmount,
            due_date: moment(),
            month_id: null,
            session_id: currentSession,
        }));

        setInstallmentData(installments);
    };

    const handleInstallmentChange = (index, field, value) => {
        const updatedData = [...installmentData];
        updatedData[index][field] = value;
        setInstallmentData(updatedData);
    };

   useQuery({
        queryKey: ['student-planfee'],
        queryFn: async () => {
            const queryParams = { id: studentId };
            const queryString = encodeQueryParams(queryParams);
            const { data } = await fetchWrapper('GET', `${urls.GET_STD_FEE_PLAN}?${queryString}`);
            if (data?.status === 200) {
                setFormValues((prev)=>({...prev,...data.data}))
            }
            return data?.data;
        },
        enabled: Boolean(studentId)
    })


    return (
        <Fragment>
            <Typography variant="h4" gutterBottom color="grey.700">
                Current Fee Plan
            </Typography>
            <Typography variant="h6" gutterBottom color="grey.700">
                Manage Student Fee
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                <Grid container spacing={2}>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                            <Select
                                label="Payment Mode"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 250
                                        }
                                    }
                                }}
                                value={formValues.fee_type}
                                onChange={(e) => {

                                    setFormValues((prev) => ({
                                        ...prev,
                                        fee_type: e.target.value,
                                        studentId: student?.studentId,
                                        std_tution_fee: 0,
                                        std_transport_fee: 0,
                                        std_outstanding_blc: 0,
                                        std_misc_fee: 0
                                    }))
                                }}
                            >
                                {paymentMods.map((mode) => (
                                    <MenuItem key={mode} value={mode.value}>
                                        {mode.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    </Grid>
                    <Grid xs={6} />
                    {
                        formValues.fee_type == 1 ?
                            <Fragment>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Annual Package"
                                        value={formValues.annual_package || ""}
                                        onChange={(e) =>
                                            setFormValues((prev) => ({
                                                ...prev,
                                                annual_package: e.target.value,
                                            }))
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>No of Installments</InputLabel>
                                        <Select disabled={!formValues.annual_package} value={formValues.no_installment} onChange={handleInstallmentCountChange}>
                                            {[...Array(6)].map((_, i) => (
                                                <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {installmentData.map((installment, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                fullWidth
                                                label={`Installment ${index + 1}`}
                                                value={installment.installment}
                                                onChange={(e) => handleInstallmentChange(index, 'installment', e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={3}>
                                            <CustomDatePicker label="Due Date" format={'DD/MM/YYYY'} value={installment.due_date} onChange={(val) => handleInstallmentChange(index, 'due_date', val)} fullWidth />
                                        </Grid>

                                        <Grid item xs={6} md={3}>
                                            <MonthSelector
                                                value={installment.month_id}
                                                onChange={(month) => handleInstallmentChange(index, 'month_id', month)}
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={3}>
                                            <SessionSelector
                                                value={installment.session_id}
                                                onChange={(e) => setFormValues((prev) => ({
                                                    ...prev,
                                                    session_id: e.target.value,
                                                }))}
                                            />
                                        </Grid>
                                    </Fragment>
                                ))}
                            </Fragment>
                            :
                            <Fragment>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        label="Monthly Fee"
                                        name="std_tution_fee"
                                        fullWidth
                                        required
                                        value={formValues.std_tution_fee}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        label="Initial Balance"
                                        fullWidth
                                        name="std_outstanding_blc"
                                        type='number'
                                        value={formValues.std_outstanding_blc}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        label="Transport Fee"
                                        fullWidth
                                        name="std_transport_fee"
                                        type='number'
                                        value={formValues.std_transport_fee}
                                        onChange={handleChange}
                                    />

                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        label={feeSettings?.branchconfigresponse?.miscTitle || "Annual Subscription"}
                                        fullWidth
                                        name="std_misc_fee"
                                        type='number'
                                        value={formValues.std_misc_fee}
                                        onChange={handleChange}
                                    />

                                </Grid>
                            </Fragment>
                    }

                </Grid>
                {/* Challan Genration On Hold */}
                {/* <Typography variant="h6" gutterBottom color="grey.700" sx={{ mt: 4 }}>
                    Create Voucher
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <MonthSelector
                            value={voucherMonth}
                            onChange={(month) => setVoucherMonth(month)}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <CustomDatePicker label="Due Date" format={'DD/MM/YYYY'} value={dueDate} onChange={setDueDate} fullWidth />
                    </Grid>

                    {
                        feeHeads?.length > 0 &&
                        feeHeads.map((feeHead, index) => (
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label={feeHead.feetype_name}
                                    type="number"
                                    fullWidth
                                    value={feeHeadsData[index]?.fee_amount}
                                    onChange={(e) => handleFeeInputChange(index, e.target.value)}

                                />
                            </Grid>
                        ))
                    }

                </Grid> */}
                <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Stack direction="row" sx={{ gap: 1 }}>
                        <Button variant="contained" type='submit' disabled={loading}>
                        {studentId ? 'Update' : 'Save'}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Fragment>
    )
}

export default AddFeePlanStep