import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  letterSpacing: 'normal',
  '& .MuiDataGrid-iconSeparator': {
    display: 'none'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.dark.main,
    color: theme.palette.grey[50]
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: '#cac9c9'
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.action.disabled}`,
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    whiteSpace: 'normal !important'
  },
  '& .MuiDataGrid-cell:first-of-type': {
    borderLeft: `1px solid ${theme.palette.action.disabled}`
  },
  '& .MuiPaginationItem-root': {
    borderColor: theme.palette.primary.main
  },
  '& .MuiDataGrid-cellContent': {
    whitespace: 'normal'
  },
  '& .MuiDataGrid-root .MuiDataGrid-cell': {
    color: 'tomato',
    whiteSpace: 'normal !important',
    wordWrap: 'break-word !important'
  }
}));

export const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
