import { types } from 'src/redux/types';
import * as Services from 'src/services';

export const GET_SESSIONS_API_HANDLER = () => async (dispatch) => {
  try {
    let response = await Services.GET_SESSIONS_API();
    dispatch({
      type: types.SESSIONS.FETCH_SUCCESS,
      payload: response.data.result
    });
    return response;
  } catch (error) {
    return error;
  }
};
